<template>
  <Transition name="modal">
    <section v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div
            class="close"
            @click="$emit('close')"
            role="button"
            aria-label="modal close button"
            tabindex="0"
          ></div>
          <aside class="modal-image">
            <img
              :src="
                currentPage === 'lifestyle'
                  ? LifestyleModalImage
                  : currentPage === 'airquality'
                  ? AirQualityModalImage
                  : FinfactsModalImage
              "
              alt="modal image"
            />
          </aside>
          <div class="modal-divider">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 222.7 786.1"
              style="enable-background: new 0 0 222.7 786.1"
              xml:space="preserve"
            >
              <image
                style="display: none; overflow: visible"
                width="433"
                height="852"
                xlink:href="8682C4FE.jpg"
                transform="matrix(1 0 0 1 -59.8869 -35.6053)"
              ></image>
              <polygon
                :fill="
                  currentPage === 'lifestyle'
                    ? '#29b370'
                    : currentPage === 'airquality'
                    ? '#0096c7'
                    : '#6fc814'
                "
                class="st0"
                points="49,786.1 0,786.1 173.8,0 222.7,0 "
              />
            </svg>
          </div>
          <aside class="modal-text-container">
            <div class="stay-informed">
              <p>Stay Informed</p>
            </div>
            <div class="join-text">
              <p>
                Subcribe to our mailing list for the latest information
                regarding all our digital signage products.
              </p>
            </div>
            <div class="email-container">
              <div class="modal-input-container">
                <input
                  type="email"
                  placeholder="Enter your email"
                  :value="modelValue"
                  @input="$emit('update:modelValue', $event.target.value)"
                />
              </div>
              <button
                class="modal-send-button"
                aria-label="email send button"
                @click.stop="$emit('createNewContact')"
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 60 60"
                  style="enable-background: new 0 0 60 60"
                  xml:space="preserve"
                >
                  <g>
                    <path
                      class="send-button"
                      :fill="
                        currentPage === 'lifestyle'
                          ? '#29b370'
                          : currentPage === 'airquality'
                          ? '#0096c7'
                          : '#6fc814'
                      "
                      d="M30,0C22,0,14.4,3.2,8.8,8.8C3.2,14.4,0,22,0,30s3.2,15.6,8.8,21.2C14.4,56.8,22,60,30,60c5.3,0,10.4-1.4,15-4
		s8.3-6.4,11-11c2.6-4.6,4-9.7,4-15c0-8-3.2-15.6-8.8-21.2C45.6,3.2,38,0,30,0L30,0z M44.7,32.6L33.4,44c-0.9,0.9-2.2,1.3-3.5,0.9
		c-1.2-0.3-2.2-1.3-2.5-2.5c-0.3-1.2,0-2.5,0.9-3.5l5.5-5.4h-16c-1.3,0-2.4-0.7-3.1-1.8c-0.6-1.1-0.6-2.5,0-3.6
		c0.6-1.1,1.8-1.8,3.1-1.8h15.9L28.3,21c-0.9-0.9-1.3-2.2-0.9-3.5c0.3-1.2,1.3-2.2,2.5-2.5c1.2-0.3,2.5,0,3.5,0.9l11.4,11.4
		c0.7,0.7,1.1,1.7,1,2.7v0.1C45.8,31.1,45.4,32,44.7,32.6L44.7,32.6z"
                    />
                  </g>
                </svg>
              </button>
            </div>
            <div class="logos-container">
              <a
                href="https://lifestyle.telecine.com/"
                target="_blank"
                aria-label="lifestyle website link"
              >
                <img
                  class="logo"
                  src="../assets/Modal/Lifestyle/Lifestyle_Indices_logo-sm.svg"
                  alt="lifestyle logo"
                />
              </a>
              <a
                href="https://airquality.telecine.com/"
                target="_blank"
                aria-label="air quality website link"
              >
                <img
                  class="logo"
                  src="../assets/Modal/AirQuality/Air_Quality_logo-sm.svg"
                  alt="air quality logo"
                />
              </a>
              <a
                href="https://finfacts.telecine.com/"
                target="_blank"
                aria-label="finfacts website link"
              >
                <img
                  class="logo"
                  src="../assets/Modal/Finfacts/FinFacts_logo-sm.svg"
                  alt="finfacts logo"
                />
              </a>
            </div>
          </aside>
        </div>
      </div>
    </section>
  </Transition>
</template>

<script>
import AirQualityModalImage from "../assets/Modal/AirQuality/Product_Brand_Image_Filter-Air_Quality.jpg";
import FinfactsModalImage from "../assets/Modal/Finfacts/Product_Brand_Image_Filter-FinFacts.jpg";
import LifestyleModalImage from "../assets/Modal/Lifestyle/Product_Brand_Image_Filter-Lifestyle_Indices.jpg";

export default {
  props: {
    show: Boolean,
    currentPage: String,
    modelValue: String,
  },
  emits: ["close", "createNewContact", "update:modelValue"],
  setup() {
    return { AirQualityModalImage, FinfactsModalImage, LifestyleModalImage };
  },
};
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  /* background-image: url("../assets/Modal/Product_Brands-Pop_Up2.png"); */
  background-size: cover;
  background-repeat: no-repeat;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  position: relative;
  width: 56.9%;
  /* height: 48%; */
  height: fit-content;
  aspect-ratio: 16 / 7.6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5%;
  margin: 0px auto;
  background-color: #fff;
  transition: all 0.3s ease;
  overflow: hidden;
}

.modal-image {
  width: 40.8%;
  max-width: 40.8%;
  min-height: 100%;
  clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
}

.modal-image > img {
  max-width: 100%;
  width: 100%;
  height: auto;
  max-height: 100%;
}

.modal-divider {
  width: 13.5%;
  max-width: 20%;
  height: 100%;
  max-height: 100%;
  position: absolute;
  left: 29.8%;
}

.modal-divider > img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.modal-text-container {
  width: 58%;
  height: 100%;
  padding: 4.7% 6.6% 3% 7.6%;
}

.stay-informed > p {
  font-family: RedhatBold;
  font-size: 5vw;
  line-height: 95%;
}

.join-text {
  margin-top: 0.882em;
  font-size: 1.04vw;
  padding-right: 4.2em;
}
.join-text > p {
  font-family: Europe;
  font-size: 1.04vw;
  line-height: 130%;
}
.email-container {
  display: flex;
  font-size: 1.13vw;
  margin-top: 1.1em;
  padding: 0.222em 0;
}

.modal-input-container {
  width: 69%;
}

.modal-input-container > input {
  padding: 4.3% 7%;
  font-size: 1.13vw;
  width: 100%;
  border-radius: 1em;
  border: 0.05em solid;
}

input:focus {
  outline: none !important;
  border-color: #29b370;
}

.modal-send-button {
  margin-left: 1.1em;
  width: 12.5%;
  max-width: 12.5%;
  background: white;
}

.modal-send-button > img {
  width: 100%;
  height: auto;
  max-height: 100%;
}

#Layer_1:hover {
  cursor: pointer;
  scale: 1.1;
}

.logos-container {
  width: 108%;
  font-size: 1vw;
  margin-top: 0.7em;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.logos-container > a {
  width: 33.33%;
  max-width: 33.33%;
  height: auto;
}

.logos-container > a:hover {
  scale: 1.1;
}

.close {
  position: absolute;
  right: 1.1%;
  top: 4%;
  width: 4%;
  height: 9%;
  opacity: 0.7;
}
.close:hover {
  opacity: 1;
  scale: 1.1;
  cursor: pointer;
}
.close:before,
.close:after {
  position: absolute;
  content: " ";
  height: 100%;
  width: 6%;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
