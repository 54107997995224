import {
    ref,
    computed
} from "@vue/reactivity";
import {
    onBeforeMount,
    onBeforeUnmount,
    onMounted
} from "vue";
// import {
//     useRoute
// } from 'vue-router'

import axios from "axios";

import {
    scrollIntoView
} from "seamless-scroll-polyfill";

// import smoothscroll from 'smoothscroll-polyfill';
// import { polyfill } from "seamless-scroll-polyfill";

export default function sharedData() {
    const name = ref("");
    const email = ref("");
    const message = ref("");
    const errorEmail = ref("");
    const errorName = ref("");
    const messageSent = ref(false);
    const currentScroll = ref(0);
    const isMenuOpen = ref(false);
    const isCovering = ref(false);
    const isMoveToTopVisible = ref(false);
    const main = ref(null);
    const showModal = ref(true);
    // const route = useRoute();


    const currentTag = computed(() => {
        // return route.path === "/air-quality" ? "Air Quality" : route.path === "/finfacts" ? "Finfacts" : route.path === "/lifestyle" ? "Lifestyle" : ""
        const host = window.location.host;
        const parts = host.split(".");
        // const domainLength = 3;

        return parts[0] === "airquality" ? "Air Quality" : parts[0] === "finfacts" ? "Finfacts" : parts[0] === "lifestyle" ? "Lifestyle" : ""
    })

    console.log(currentTag.value);

    const currentViewport = computed(() => {
        const vw = Math.max(
            document.documentElement.clientWidth || 0,
            window.innerWidth || 0
        );

        // const ua = navigator.userAgent;
        // if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        //   return "tablet";
        // } else if (
        //   /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        //     ua
        //   )
        // ) {
        //   return "mobile";
        // }
        // return "desktop";

        if (vw <= 576) {
            return "mobile";
        } else if (vw > 576 && vw <= 1024) {
            return "tablet";
        } else {
            return "desktop";
        }
    });

    const isSideMenuVisible = computed(() => {
        if (
            (currentViewport.value === "desktop" ||
                currentViewport.value === "tablet") &&
            currentScroll.value >= 11
        ) {
            return true;
        } else if (currentViewport.value === "mobile") {
            return true;
        }
    });

    const aboutUsParagraph1Text = computed(() => {
        if (
            currentViewport.value === "desktop" ||
            currentViewport.value === "tablet"
        ) {
            return "Provide those who work in or visit your building with an automated, sensor-driven content feed on information screens that show the real-time state of air quality, across multiple indicators - from temperature and humidity to carbon dioxide and particulates levels";
        } else if (currentViewport.value === "mobile") {
            return "We provide your building with an automated, sensor-driven content feed on screens that show the live state of air quality from indoor air quality sensors. We show these conditions in simple, at-a-glance graphics, based on real-time data.";
        }
    });

    const isContactButtonDisabled = computed(() => {
        if (!name.value || !email.value || !message.value || errorEmail.value) {
            return true;
        } else {
            return false;
        }
    });

    const tvTopBottom = computed(() => {
        if (
            currentScroll.value >= 41 &&
            currentScroll.value <= 58 &&
            currentScroll.value != 49
        ) {
            const scrolledPercent = Math.round(
                ((58 - currentScroll.value) / 17) * 100
            );
            // console.log("scrolledPercent: ", scrolledPercent);
            const value = (scrolledPercent * (22.5 - 1)) / 100 + 1;
            // console.log("value: ", value);
            return `transform: translateY(${value}%)`;
        } else {
            return `transform: translateY(12.5%)`;
        }
    });

    // const gcd = (width, height) => {
    //     return height == 0 ? width : gcd(height, width % height);
    // };

    // const innerWidth = window.innerWidth;
    // const innerHeight = window.innerHeight;
    const availHeight = window.screen.availHeight;
    const availWidth = window.screen.availWidth;
    // const currentRatio = gcd(innerWidth, innerHeight);
    // const aspectRatio = `${innerWidth / currentRatio}:${
    //   innerHeight / currentRatio
    // }`;

    // console.log("window.innerWidth: ", window.innerWidth);
    // console.log("window.innerHeight: ", window.innerHeight);
    // console.log("window.screen.availHeight: ", window.screen.availHeight);
    // console.log("window.screen.availWidth: ", window.screen.availWidth);
    // console.log("aspectRatio: ", aspectRatio);

    const scrollPageTo = (navEl) => {

        let element = document.querySelector(`.${navEl}`);
        // console.log(element);

        if (navEl === "page2-inner-main-container") {
            isCovering.value = true;
            clearTimeout(coverTimeout);
            const coverTimeout = setTimeout(() => {
                // element.scrollIntoView(true);
                scrollIntoView(element, {
                    behavior: "smooth",
                    block: "center",
                    inline: "center",
                });
            }, 1050);

            clearTimeout(uncoverTimeout);
            const uncoverTimeout = setTimeout(() => {
                isCovering.value = false;
            }, 1800);
        } else {
            // element.scrollIntoView(true);
            scrollIntoView(element, {
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
        }
    };

    const toggleMenu = () => {
        isMenuOpen.value = !isMenuOpen.value;
    };

    const validateEmail = () => {
        const reg =
            /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
        if (email.value == null || email.value == "") {
            errorEmail.value = "Please Enter Email";
        } else if (!reg.test(email.value)) {
            errorEmail.value = "Please Enter Correct Email";
        } else {
            errorEmail.value = "";
        }
    };

    const validateName = () => {
        if (!name.value) {
            errorName.value = "Please Enter Name";
        } else {
            errorName.value = "";
        }
    };

    const onSubmitContactForm = (source) => {
        // validateName();
        // validateEmail();

        axios.post("/api/constant-contact", {
                from: name.value,
                email: email.value,
                tag: currentTag.value
            })
            .then(res => {
                if (res.status === 200) {
                    console.log("Email subscribed to Telecine Newsletter")
                } else {
                    console.log(res.error);
                }
            })
            .catch(err => console.error(err.response.data.message))

        if (source === "contact-form") {
            axios
                .post("/api/message", {
                    from: `${name.value} <${email.value}>`,
                    to: "info@telecine.ca",
                    // cc: [
                    //   {
                    //     name: "Nicolae Coman",
                    //     address: "nicolae@telecine.ca",
                    //   },
                    // ],
                    subject: "",
                    text: message.value,
                })
                .then((res) => {
                    console.log(res);
                    name.value = "";
                    email.value = "";
                    message.value = "";
                    messageSent.value = true;
                    clearTimeout(currentTimeout);
                    const currentTimeout = setTimeout(() => {
                        messageSent.value = false;
                    }, 5000);
                })
                .catch((err) => {
                    console.log(err.response.data.message);
                });
        }

        name.value = "";
        email.value = "";
        message.value = "";
    };

    // const bindScrollSnap = () => {
    //   const element = main.value;
    //   createScrollSnap(
    //     element,
    //     {
    //       snapDestinationY: "90%",
    //     },
    //     () => console.log("snapped")
    //   );
    // };

    const deactivateScrollSnap = () => {
        var is_chrome = navigator.userAgent.indexOf("Chrome") > -1;
        var is_explorer = navigator.userAgent.indexOf("MSIE") > -1;
        var is_firefox = navigator.userAgent.indexOf("Firefox") > -1;
        var is_safari = navigator.userAgent.indexOf("Safari") > -1;
        var is_opera = navigator.userAgent.toLowerCase().indexOf("op") > -1;
        var is_Mac = navigator.userAgent.toUpperCase().indexOf("MAC") >= 0;
        if (is_chrome && is_safari) {
            is_safari = false;
        }
        if (is_chrome && is_opera) {
            is_chrome = false;
        }

        if (
            is_explorer ||
            /iPhone|iPad|iPod|IEMobile/i.test(navigator.userAgent) ||
            (is_Mac && is_firefox)
        ) {
            document.getElementsByTagName("html")[0].style.scrollSnapType = "none";
        }
    };

    onBeforeMount(() => {});
    onMounted(() => {
        // polyfill();
        // bindScrollSnap();

        if (currentTag.value !== "Finfacts") {
            clearTimeout(topImageTimeOut);
            const topImageTimeOut = setTimeout(() => {
                document.querySelector(".top-first-small-overlay").style.mixBlendMode = "exclusion"
            }, 1000)
        }

        //Intersection Observer code >>>> Works fine but get animation glitches while snapping
        const keyBenefits = document.querySelector(".key-benefits");
        const contactFormMainContainer = document.querySelector(
            ".contact-info-main-container"
        );

        const keyBenefitsObserver = new IntersectionObserver(
            (entries) => {
                // console.log(entries);
                entries.forEach((entry) => {
                    // console.log(entry);
                    entry.target.classList.toggle(
                        "key-benefits-appear",
                        entry.isIntersecting
                    );

                    // To Keep the items on screen once they are loaded by animating
                    if (entry.isIntersecting) {
                        keyBenefitsObserver.unobserve(entry.target);
                    }
                });
            }, {
                threshold: 1
            }
        );
        keyBenefitsObserver.observe(keyBenefits);

        const moveToTopObserver = new IntersectionObserver(
            (entries) => {
                // console.log(entries);
                entries.forEach((entry) => {
                    // console.log(entry);
                    isMoveToTopVisible.value = entry.isIntersecting;

                    // To Keep the items on screen once they are loaded by animating
                    // if (entry.isIntersecting) {
                    //   keyBenefitsObserver.unobserve(entry.target);
                    // }
                });
            }, {
                threshold: 0.9
            }
        );
        moveToTopObserver.observe(contactFormMainContainer);

        const initialWidth = window.innerWidth;
        const initialHeight = window.innerHeight;
        const metaViewport = document.querySelector("meta[name=viewport]");

        if (/Android/i.test(navigator.userAgent)) {
            window.addEventListener("resize", (e) => {
                console.log(e);
                if (window.innerWidth > initialWidth) {
                    // document.documentElement.style.setProperty('overflow', 'hidden')
                    // alert("window.innerWidth > initialWidth");
                    metaViewport.setAttribute(
                        "content",
                        "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                    );
                } else if (
                    window.innerWidth == initialWidth &&
                    window.innerHeight < initialHeight
                ) {
                    // alert(
                    //   "window.innerWidth == initialWidth && window.innerHeight < initialHeight"
                    // );
                    metaViewport.setAttribute(
                        "content",
                        "height=" +
                        initialHeight +
                        "px, width=device-width, initial-scale=1.0"
                    );
                } else if (window.innerHeight == initialHeight) {
                    // alert("window.innerHeight == initialHeight");
                    metaViewport.setAttribute(
                        "content",
                        "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                    );
                }
            });
        }

        deactivateScrollSnap();

        window.addEventListener(
            "scroll",
            () => {
                const heightOfWindow = window.innerHeight;
                // console.log("heightOfWindow: ", heightOfWindow);
                const contentScrolled = window.pageYOffset;
                // console.log("contentScrolled: ", contentScrolled);
                const bodyHeight = document.body.offsetHeight;
                // console.log("bodyHeight: ", bodyHeight);

                const total = bodyHeight - heightOfWindow;
                // console.log("total: ", total);
                const got = contentScrolled;
                // console.log("got: ", got);
                const percent = parseInt((got / total) * 100);

                // console.log("percent: ", percent);
                currentScroll.value = percent;

                // console.log(window.scrollY);
                // currentScroll.value = window.scrollY;
            },
            true
        );
    });

    onBeforeUnmount(() => {

    });



    return {
        main,
        availHeight,
        availWidth,
        innerHeight,
        innerWidth,
        scrollPageTo,
        toggleMenu,
        isMenuOpen,
        isCovering,
        name,
        email,
        message,
        errorEmail,
        errorName,
        validateEmail,
        validateName,
        isContactButtonDisabled,
        onSubmitContactForm,
        messageSent,
        currentScroll,
        tvTopBottom,
        currentViewport,
        aboutUsParagraph1Text,
        isSideMenuVisible,
        isMoveToTopVisible,
        showModal
    };
}