<template>
  <main class="main-container mandatory-scroll-snapping" ref="main">
    <Teleport to="body">
      <!-- use the modal component, pass in the prop -->
      <modal
        :show="showModal"
        currentPage="lifestyle"
        @close="showModal = false"
        @createNewContact="onSubmitContactForm('newsletter')"
        v-model="email"
      >
      </modal>
    </Teleport>
    <div v-if="isSideMenuVisible" class="fixed-menu-container">
      <transition name="menu" mode="out-in" appear>
        <div
          v-if="
            !isMenuOpen &&
            (currentViewport === 'tablet' || currentViewport === 'mobile') &&
            isMoveToTopVisible
          "
          class="fixed-move-to-top"
          @click.prevent="scrollPageTo('top')"
          tabindex="0"
          role="button"
          aria-label="move to top button"
        ></div>
        <div
          v-else-if="!isMenuOpen"
          class="menu-icon"
          @click="toggleMenu"
          tabindex="0"
          role="button"
        ></div>
        <div v-else class="menu">
          <p @click="toggleMenu">X</p>

          <p
            v-if="currentViewport != 'desktop'"
            @click.prevent="scrollPageTo('top')"
          >
            HOME
          </p>
          <p @click.prevent="scrollPageTo('page2-inner-main-container')">
            ABOUT
          </p>
          <p @click.prevent="scrollPageTo('page3-inner-main-container')">
            FEATURES
          </p>
          <p @click.prevent="scrollPageTo('page3-inner-main-container')">
            Key Benefits
          </p>
          <p @click.prevent="scrollPageTo('page4-inner-main-container')">
            Categories
          </p>
          <p @click.prevent="scrollPageTo('page5-inner-main-container')">
            Content Samples
          </p>
          <!-- <p @click.prevent="scrollPageTo('page7-inner-main-container')">
            Pricing
          </p> -->
          <p @click.prevent="scrollPageTo('page8-inner-main-container')">
            Contact
          </p>
          <p v-if="currentViewport === 'desktop'"></p>
        </div>
      </transition>
    </div>

    <transition name="to-top" mode="out-in" appear>
      <div
        v-if="isSideMenuVisible && currentViewport === 'desktop'"
        class="fixed-move-to-top"
        @click.prevent="scrollPageTo('top')"
        tabindex="0"
        role="button"
        aria-label="move to top button"
      ></div>
    </transition>
    <div id="inner-container-1" class="inner-container top">
      <transition name="top-image" appear>
        <div class="top-image-container">
          <!-- <img src="../assets/AirQuality/AQ-Top_Image.png" /> -->
        </div>
      </transition>
      <div
        class="top-first-small-overlay"
        :class="{ 'cover-top-image': isCovering }"
      ></div>
      <div class="top-first-big-overlay">
        <header class="header-container">
          <div class="logo-container">
            <img src="../assets/Lifestyle/LI_Logo.png" alt="logo" />
          </div>
          <div class="navbar-container flex-center">
            <nav>
              <ul>
                <li
                  class="flex-center"
                  @click.prevent="scrollPageTo('page2-inner-main-container')"
                  tabindex="0"
                >
                  ABOUT
                </li>
                <li
                  class="flex-center"
                  @click.prevent="scrollPageTo('page3-inner-main-container')"
                  tabindex="0"
                >
                  FEATURES
                </li>
                <li
                  class="flex-center"
                  @click.prevent="scrollPageTo('page8-inner-main-container')"
                  tabindex="0"
                >
                  CONTACT
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <transition name="page1-text" appear>
          <div class="page1-description">
            <h1>Data for everyday life.</h1>
            <p>
              With live data for every lifestyle, our digital signage solution
              will draw attention to what's important to people.
            </p>
          </div>
        </transition>
      </div>
      <div class="arrow-container">
        <div
          class="down-arrow dark"
          @click.prevent="scrollPageTo('page2-inner-main-container')"
          role="button"
          tabindex="0"
          aria-label="move to next page arrow"
        ></div>
      </div>
      <a
        class="telecine-logo"
        href="https://www.telecine.ca/"
        target="_blank"
        aria-label="telecine website link"
      ></a>
    </div>

    <div id="inner-container-2" class="inner-container">
      <div class="page2-background"></div>
      <div class="page2-inner-main-container">
        <div class="page2-description flex-center">
          <p class="page-heading-text">Who we are</p>
          <p v-if="currentViewport != 'mobile'">
            Weather forecasts and outside conditions drive lifestyle decisions,
            so why not put information in front of people that mashes up weather
            data and helps decision-making for doing the things we love? A look
            outside might suggest the skies are clear and conditions right for a
            run, but what if screens around a venue provided an hourly forecast
            of running prospects, based on heat, rain and air pollution
            readings? Or for sailing? Or the commute home! Forecasts tuned to
            personal lifestyles and pursuits is the driver behind a fun,
            light-hearted new automated subscription content service from
            Telecine that complements the weather content used on many digital
            signage networks.
          </p>
          <p v-else>
            Weather forecasts and outside conditions drive lifestyle decisions,
            so why not put information in front of people that mashes up weather
            data and helps decision-making for doing the things we love? Our
            lifestyle forecasts are tuned to personal lifestyles in a
            light-hearted new automated subscription content service from
            Telecine.
          </p>
          <!-- <p>
            {{ aboutUsParagraph1Text }}
          </p>
          <p v-if="currentViewport != 'mobile'">
            Dynamic, templated digital charts that both show and explain current
            conditions in simple, at-a-glance graphics, based on data from
            indoor air quality sensors.
          </p> -->
        </div>
        <div class="page2-tv-image-container">
          <div class="page2-tv-image-inner-container"></div>
        </div>
        <div
          class="arrow-container"
          @click.prevent="scrollPageTo('page3-inner-main-container')"
        >
          <div
            class="down-arrow dark"
            role="button"
            tabindex="0"
            aria-label="move to next page arrow"
          ></div>
        </div>
      </div>
    </div>

    <div id="inner-container-3" class="inner-container">
      <div class="page3-background"></div>
      <div class="page3-inner-main-container">
        <p class="page-heading-text">Key Benefits</p>
        <!-- <div
          class="key-benefits"
          :class="[currentScroll >= 22 ? 'key-benefits-appear' : '']"
        > -->
        <div class="key-benefits">
          <div class="individual-benefit">
            <div class="benefit-image-container flex-center">
              <img
                src="../assets/Lifestyle/key_benefits/Variety.svg"
                alt="indoor health image"
              />
            </div>
            <div class="key-benefits-description-container">
              <p>Variety</p>
              <p>
                Fun, at–a–glance views of a variety of lifestyle scenarios and
                pursuits.
              </p>
            </div>
          </div>
          <div class="individual-benefit">
            <div class="benefit-image-container flex-center">
              <img
                src="../assets/Lifestyle/key_benefits/Weather.svg"
                alt="numerous indicators image"
              />
            </div>
            <div class="key-benefits-description-container">
              <p>Complement Weather</p>
              <p>
                Great additions to more conventional, ubiquitous weather
                conditions and forecasts.
              </p>
            </div>
          </div>
          <div class="individual-benefit">
            <div class="benefit-image-container flex-center">
              <img
                src="../assets/Lifestyle/key_benefits/Automation.svg"
                alt="automation image"
              />
            </div>
            <div class="key-benefits-description-container">
              <p>Automation</p>
              <p>
                Set-it-and-forget-it automation on updates minimizes management.
              </p>
            </div>
          </div>
          <div class="individual-benefit">
            <div class="benefit-image-container flex-center">
              <img
                src="../assets/Lifestyle/key_benefits/Easy.svg"
                alt="flexible technical model image"
              />
            </div>
            <div class="key-benefits-description-container">
              <p>Easy to Understand</p>
              <p>
                Simplified, easily understood graphics showing readings are
                good, requiring caution or unhealthy.
              </p>
            </div>
          </div>
          <div class="individual-benefit">
            <div class="benefit-image-container flex-center">
              <img
                src="../assets/Lifestyle/key_benefits/Trusted.svg"
                alt="content management image"
              />
            </div>
            <div class="key-benefits-description-container">
              <p>Trusted Sources</p>
              <p>Trusted data sources, available down to local levels.</p>
            </div>
          </div>
          <div class="individual-benefit">
            <div class="benefit-image-container flex-center">
              <img
                src="../assets/Lifestyle/key_benefits/Flexible.svg"
                alt="accessible graphics image"
              />
            </div>
            <div class="key-benefits-description-container">
              <p>Flexible Model</p>
              <p>
                Content management platform-friendly HTML5 content templates
                work across almost any digital signage network.
              </p>
            </div>
          </div>
          <div class="individual-benefit">
            <div class="benefit-image-container flex-center">
              <img
                src="../assets/Lifestyle/key_benefits/Scalable.svg"
                alt="scalable image"
              />
            </div>
            <div class="key-benefits-description-container">
              <p>Easily Scalable</p>
              <p>
                Easily scalable content delivery, regardless of branch network
                size and complexity.
              </p>
            </div>
          </div>
        </div>
        <div
          class="arrow-container"
          @click.prevent="scrollPageTo('page4-inner-main-container')"
        >
          <div
            class="down-arrow white"
            role="button"
            tabindex="0"
            aria-label="move to next page arrow"
          >
            >
          </div>
        </div>
      </div>
    </div>
    <div class="page3-lower-background-main-container"></div>

    <div id="inner-container-4" class="inner-container">
      <div class="page4-background"></div>
      <div class="page4-inner-main-container">
        <div class="metrics-container">
          <p class="page-heading-text">Main Categories</p>
          <div class="metrics">
            <p>- Driving Difficulty</p>
            <p>- Pollen</p>
            <p>- Dry skin</p>
            <p>- Outdoor air quality</p>
            <p>- Breathing forecast</p>
            <p>- Static electricity</p>
            <p>- Aches & pain</p>
            <p>- Hair Frizz</p>
            <p>- UV index</p>
            <p>- Running weather</p>
          </div>
        </div>
        <div class="background-metrics">
          <!-- <img src="../assets/AirQuality/Air_Quality_Metrics.png" /> -->
        </div>
        <div></div>
      </div>
      <div
        class="arrow-container"
        @click.prevent="scrollPageTo('page5-inner-main-container')"
      >
        <div
          class="down-arrow white"
          role="button"
          tabindex="0"
          aria-label="move to next page arrow"
        >
          >
        </div>
      </div>
    </div>
    <div class="page4-lower-background-main-container"></div>

    <div id="inner-container-5" class="inner-container">
      <div class="page5-inner-main-container">
        <p class="page-heading-text">Premium Content</p>
        <div class="tv-images-container">
          <div class="tv-images-individual-container">
            <img
              v-if="currentViewport != 'mobile'"
              src="../assets/Lifestyle/LI-Premium_Content_Left_TV.png"
              alt="left tv image"
              :style="tvTopBottom"
            />
            <img
              v-else
              src="../assets/Lifestyle/Mobile_assets/LI_Premium_Content-TV_1.png"
              alt="left tv image"
            />
          </div>
          <div class="tv-images-individual-container">
            <img
              v-if="currentViewport != 'mobile'"
              src="../assets/Lifestyle/LI-Premium_Content_Right_TV.png.png"
              alt="right tv image"
              :style="tvTopBottom"
            />
            <img
              v-else
              src="../assets/Lifestyle/Mobile_assets/LI_Premium_Content-TV_2.png"
              alt="right tv image"
            />
          </div>
          <!-- <p>* Live Data from the Telecine Office</p> -->
        </div>
      </div>
      <div
        class="arrow-container"
        @click.prevent="scrollPageTo('page6-inner-main-container')"
      >
        <div
          class="down-arrow white"
          role="button"
          tabindex="0"
          aria-label="move to next page arrow"
        >
          >
        </div>
      </div>
    </div>

    <div id="inner-container-6" class="inner-container">
      <div class="page6-background"></div>
      <div class="page6-inner-main-container">
        <div class="video-container">
          <img
            class="video-image"
            src="../assets/Lifestyle/Lifestyle_Video_Placeholder.png"
            alt="video"
          />
          <img
            v-if="currentViewport === 'mobile'"
            class="play-button"
            src="../assets/Lifestyle/Mobile_assets/Play_Button.svg"
            alt="play button"
          />
          <div class="overlay"></div>
        </div>
      </div>
      <div
        class="arrow-container"
        @click.prevent="scrollPageTo('page8-inner-main-container')"
      >
        <div
          class="down-arrow white"
          role="button"
          tabindex="0"
          aria-label="move to next page arrow"
        >
          >
        </div>
      </div>
    </div>

    <!-- <div id="inner-container-7" class="inner-container">
      <div class="page7-background"></div>
      <div class="page7-inner-main-container">
        <p class="page-heading-text">Pricing starting at</p>
        <div><span>$12.50</span><span>/Month</span></div>
        <div
          class="contact-us-button-container"
          @click.prevent="scrollPageTo('page8-inner-main-container')"
        >
          <p>Want to know more?</p>
          <p>Contact Us</p>
        </div>
      </div>
    </div>
    <div class="page7-lower-background-main-container">
      <div class="page7-lower-background-container"></div>
    </div> -->

    <div id="inner-container-8" class="inner-container">
      <div class="page8-inner-main-container">
        <p class="page-heading-text">Want to know more?</p>
        <div class="contact-info-main-container">
          <form
            class="form-container"
            @submit.prevent="onSubmitContactForm('contact-form')"
          >
            <div>
              <img src="../assets/Lifestyle/LI_Logo.png" alt="Lifestyle Logo" />
            </div>
            <input
              placeholder="Name*"
              v-model="name"
              required
              @blur="validateName"
            />
            <!-- <p v-if="errorName" class="error">{{ errorName }}</p> -->
            <input
              type="email"
              placeholder="Email*"
              v-model="email"
              required
              @blur="validateEmail"
              @change="validateEmail"
            />
            <!-- <p v-if="errorEmail" class="error">{{ errorEmail }}</p> -->
            <textarea placeholder="Message" name="body" v-model="message" />
            <p class="required">*Required</p>
            <p v-if="messageSent" class="message-sent">
              Your message is sent successfully.
            </p>
            <button>Contact Us</button>
          </form>
          <footer class="info-container">
            <div class="powered-by">
              <p>Powered By</p>
              <a
                href="https://www.telecine.ca/"
                target="_blank"
                aria-label="telecine website link"
              >
                <img
                  src="../assets/AirQuality/Telecine_Logo.png"
                  alt="telecine logo"
                  role="button"
                  tabindex="0"
                />
              </a>
            </div>
            <p class="address">
              2281 rue Ste-Catherine Est Montreal, QC H2K 2J3
            </p>
            <p class="email">info@telecine.ca</p>
            <div class="number-container">
              <p>+ 1 514.879.1177</p>
              <p>+ 1 877.444.1177</p>
            </div>
          </footer>
        </div>
        <div class="bottom-image" v-if="currentViewport === 'tablet'"></div>
      </div>
      <!-- <div
        v-if="currentViewport != 'mobile'"
        class="arrow-container"
        @click.prevent="scrollPageTo('top-image-container')"
      >
        <div v-if="currentViewport === 'desktop'" class="up-arrow"></div>
      </div> -->
    </div>

    <!-- <div
      v-if="currentViewport === 'tablet'"
      id="inner-container-9"
      class="inner-container"
    >
      <div class="page9-inner-main-container"></div>
    </div> -->
  </main>
</template>

<script>
import sharedData from "../utilities/sharedData";
import Modal from "./Modal.vue";

export default {
  name: "Lifestyle",
  components: { Modal },
  setup() {
    const {
      main,
      availHeight,
      availWidth,
      innerHeight,
      innerWidth,
      scrollPageTo,
      toggleMenu,
      isMenuOpen,
      isCovering,
      name,
      email,
      message,
      errorEmail,
      errorName,
      validateEmail,
      validateName,
      isContactButtonDisabled,
      onSubmitContactForm,
      messageSent,
      currentScroll,
      tvTopBottom,
      currentViewport,
      aboutUsParagraph1Text,
      isSideMenuVisible,
      isMoveToTopVisible,
      showModal,
    } = sharedData();

    return {
      main,
      availHeight,
      availWidth,
      innerHeight,
      innerWidth,
      scrollPageTo,
      toggleMenu,
      isMenuOpen,
      isCovering,
      name,
      email,
      message,
      errorEmail,
      errorName,
      validateEmail,
      validateName,
      isContactButtonDisabled,
      onSubmitContactForm,
      messageSent,
      currentScroll,
      tvTopBottom,
      currentViewport,
      aboutUsParagraph1Text,
      isSideMenuVisible,
      isMoveToTopVisible,
      showModal,
    };
  },
};
</script>

<style scoped>
@import url("../css/global.css");

.main-container {
  --dark-primary-color: #004e64;
  --light-primary-color: #29b370;
}

.menu p:nth-of-type(odd) {
  background: #29b370;
}
.menu p:nth-of-type(even) {
  background: #26a366;
}

.menu p:nth-of-type(odd):hover {
  background: #27a86a;
}
.menu p:nth-of-type(even):hover {
  background: #239960;
}

.menu-icon {
  background-image: url("../assets/Lifestyle/Menu.svg");
}

.fixed-move-to-top {
  background-image: url("../assets/Lifestyle/Back_to_Top.svg");
}

input:focus,
textarea:focus {
  outline: none !important;
  border-color: #29b370;
}

.individual-benefit {
  background: #e1fff0;
}

.background-metrics {
  height: 62%;
}

.top-image-container {
  background-image: url("../assets/Lifestyle/LI-Top_Image.png");
}

.top-first-big-overlay {
  background-image: url("../assets/Lifestyle/LI-Image_2-Background_Overlay-Soft_Light.png");
}

.page2-background {
  background-image: url("../assets/Lifestyle/LI_Page2_Light_Background.png");
}

.page2-tv-image-inner-container {
  background-image: url("../assets/Lifestyle/LI-Top_TV.png");
}

.page3-background {
  background-image: url("../assets/Lifestyle/key_benefits_svg_background.svg");
}

.page4-background {
  background-image: url("../assets/Lifestyle/LifestyleIndices_Metrics_Background.png");
}

.metrics-container {
  background-image: url("../assets/Lifestyle/Finfacts_Metrics_Image_Overlay.svg");
}

.background-metrics {
  background-image: url("../assets/Lifestyle/LifestyleIndices_Metrics.png");
}

.page6-background {
  background-image: url("../assets/Lifestyle/Video_Background_New.png");
}

.page7-lower-background-container {
  background-image: url("../assets/Lifestyle/test.png");
}

.page9-inner-main-container {
  /* background-image: url("../assets/Lifestyle/Air_Quality_Metrics_Backdrop.svg"); */
}

@media only screen and (max-width: 576px) and (orientation: portrait) {
  .top-image-container {
    background-position: 62% 0%;
  }

  .page2-description p:not(:first-child) {
    font-size: 4.5vw;
  }

  .background-metrics {
    height: 18%;
  }

  /* 
  .page2-description {
    height: 55%;
  }

  .page2-tv-image-container {
    height: 45%;
  } */

  .page7-lower-background-container {
    top: -153%;
    bottom: -400%;
  }

  .page2-tv-image-inner-container {
    background-image: url("../assets/Lifestyle/Mobile_assets/LI_Who_We_Are-TV.png");
  }

  .page3-background {
    background-image: url("../assets/Lifestyle/Mobile_assets/Key_Metrics_New_Resized.svg");
  }

  .page4-background {
    /* background-image: url("../assets/Lifestyle/Mobile_assets/Air_Quality_Metrics_Backdrop.svg"); */
  }

  .page6-background {
    background-image: url("../assets/Lifestyle/Mobile_assets/Video_Backdrop-Top.svg"),
      url("../assets/Lifestyle/Mobile_assets/Video_Backdrop-Bottom.svg");
  }

  .page6-inner-main-container {
    background-image: url("../assets/Lifestyle/Mobile_assets/LI_Video_Background.png");
  }

  .menu-icon {
    background-image: url("../assets/Lifestyle/Mobile_assets/Menu.svg");
  }

  @media (min-aspect-ratio: 50/93) {
    .page2-description p:not(:first-child) {
      font-size: 4vw;
    }
  }
}

@media only screen and (min-width: 577px) and (max-width: 1024px) and (orientation: portrait) {
  .top-image-container {
    background-position: 62% 0%;
  }
  .page2-tv-image-inner-container {
    background-image: url("../assets/Lifestyle/Mobile_assets/LI_Who_We_Are-TV.png");
  }

  .page3-background {
    background-image: url("../assets/Lifestyle/Tablet_assets/Key_Benefits_Background.svg");
  }

  .metrics-container {
    background-image: url("../assets/Lifestyle/Tablet_assets/Key_Benefits_Backdrop-Transparent_Gradient.svg");
  }

  .page6-background {
    background-image: url("../assets/Lifestyle/Tablet_assets/Video_Backdrop.svg");
  }

  .page7-lower-background-container {
    background-image: url("../assets/Lifestyle/Tablet_assets/SVG_Before_Contact_Form_Page.svg");
  }

  .page9-inner-main-container {
    background-image: url("../assets/Lifestyle/Tablet_assets/Finfacts_Metrics_Backdrop.svg");
  }

  .page2-description {
    height: 40%;
    padding-bottom: 0%;
  }

  .page2-description p:last-child {
    margin-top: 0%;
  }

  .page2-inner-main-container .arrow-container {
    bottom: 10%;
  }

  .page4-background {
    top: 12%;
  }

  .background-metrics {
    height: 50%;
  }

  .metrics p:last-child {
    /* width: 98%; */
  }

  .bottom-image {
    background-image: url("../assets/Lifestyle/Finfacts_Metrics_Backdrop.svg");
  }
}

@media screen and (min-width: 1025px) {
  .page2-description p:not(:first-child) {
    font-size: 1.05vw;
    line-height: 2;
  }

  .metrics {
    height: 25%;
  }

  .metrics p {
    width: 20%;
  }

  .page3-background {
    background-image: url("../assets/Lifestyle/key_benefits_svg_background.svg");
  }

  .page4-background {
    background-image: url("../assets/Lifestyle/Finfacts_Metrics_Backdrop.svg");
    top: -9%;
    bottom: -7.3%;
  }

  .metrics-container {
    background-image: url("../assets/Lifestyle/Finfacts_Metrics_Image_Overlay.svg");
  }
}
</style>
