<template>
  <main class="main-container mandatory-scroll-snapping" ref="main">
    <Teleport to="body">
      <!-- use the modal component, pass in the prop -->
      <modal
        :show="showModal"
        currentPage="airquality"
        @close="showModal = false"
        @createNewContact="onSubmitContactForm('newsletter')"
        v-model="email"
      >
      </modal>
    </Teleport>
    <div v-if="isSideMenuVisible" class="fixed-menu-container">
      <transition name="menu" mode="out-in" appear>
        <div
          v-if="
            !isMenuOpen &&
            (currentViewport === 'tablet' || currentViewport === 'mobile') &&
            isMoveToTopVisible
          "
          class="fixed-move-to-top"
          @click.prevent="scrollPageTo('top')"
          tabindex="0"
          role="button"
          aria-label="move to top button"
        ></div>
        <div
          v-else-if="!isMenuOpen"
          class="menu-icon"
          @click="toggleMenu"
          tabindex="0"
          role="button"
        ></div>
        <div v-else class="menu">
          <p @click="toggleMenu">X</p>

          <p
            v-if="currentViewport != 'desktop'"
            @click.prevent="scrollPageTo('top')"
          >
            HOME
          </p>
          <p @click.prevent="scrollPageTo('page2-inner-main-container')">
            ABOUT
          </p>
          <p @click.prevent="scrollPageTo('page3-inner-main-container')">
            FEATURES
          </p>
          <p @click.prevent="scrollPageTo('page3-inner-main-container')">
            Key Benefits
          </p>
          <p @click.prevent="scrollPageTo('page4-inner-main-container')">
            Air Quality Metrics
          </p>
          <p @click.prevent="scrollPageTo('page5-inner-main-container')">
            Content Samples
          </p>
          <!-- <p @click.prevent="scrollPageTo('page7-inner-main-container')">
            Pricing
          </p> -->
          <p @click.prevent="scrollPageTo('page8-inner-main-container')">
            Contact
          </p>
          <p v-if="currentViewport === 'desktop'"></p>
        </div>
      </transition>
    </div>

    <transition name="to-top" mode="out-in" appear>
      <div
        v-if="isSideMenuVisible && currentViewport === 'desktop'"
        class="fixed-move-to-top"
        @click.prevent="scrollPageTo('top')"
        tabindex="0"
        role="button"
        aria-label="move to top button"
      ></div>
    </transition>
    <div id="inner-container-1" class="inner-container top">
      <transition name="top-image" appear>
        <div class="top-image-container">
          <!-- <img src="../assets/AirQuality/AQ-Top_Image.png" /> -->
        </div>
      </transition>
      <div
        class="top-first-small-overlay"
        :class="{ 'cover-top-image': isCovering }"
      ></div>
      <div class="top-first-big-overlay">
        <header class="header-container">
          <div class="logo-container">
            <img src="../assets/AirQuality/AQ_Logo.png" alt="logo" />
          </div>
          <div class="navbar-container flex-center">
            <nav>
              <ul>
                <li
                  class="flex-center"
                  @click.prevent="scrollPageTo('page2-inner-main-container')"
                >
                  ABOUT
                </li>
                <li
                  class="flex-center"
                  @click.prevent="scrollPageTo('page3-inner-main-container')"
                >
                  FEATURES
                </li>
                <li
                  class="flex-center"
                  @click.prevent="scrollPageTo('page8-inner-main-container')"
                >
                  CONTACT
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <transition name="page1-text" appear>
          <div class="page1-description">
            <h1>Breathe easier.</h1>
            <p>
              With our turn-key product for air quality data analysis and
              dynamic results as digital signage.
            </p>
          </div>
        </transition>
      </div>
      <div class="arrow-container">
        <div
          class="down-arrow dark"
          @click.prevent="scrollPageTo('page2-inner-main-container')"
          role="button"
          tabindex="0"
          aria-label="move to next page arrow"
        ></div>
      </div>
      <a
        class="telecine-logo"
        href="https://www.telecine.ca/"
        target="_blank"
        aria-label="telecine website link"
      ></a>
    </div>

    <div id="inner-container-2" class="inner-container">
      <div class="page2-background"></div>
      <div class="page2-inner-main-container">
        <div class="page2-description flex-center">
          <p class="page-heading-text">Who we are</p>
          <p>
            {{ aboutUsParagraph1Text }}
          </p>
          <p v-if="currentViewport != 'mobile'">
            Dynamic, templated digital charts that both show and explain current
            conditions in simple, at-a-glance graphics, based on data from
            indoor air quality sensors.
          </p>
        </div>
        <div class="page2-tv-image-container">
          <div class="page2-tv-image-inner-container"></div>
        </div>
        <div
          class="arrow-container"
          @click.prevent="scrollPageTo('page3-inner-main-container')"
        >
          <div
            class="down-arrow dark"
            role="button"
            tabindex="0"
            aria-label="move to next page arrow"
          ></div>
        </div>
      </div>
    </div>

    <div id="inner-container-3" class="inner-container">
      <div class="page3-background"></div>
      <div class="page3-inner-main-container">
        <p class="page-heading-text">Key Benefits</p>
        <!-- <div
          class="key-benefits"
          :class="[currentScroll >= 22 ? 'key-benefits-appear' : '']"
        > -->
        <div class="key-benefits">
          <div class="individual-benefit">
            <div class="benefit-image-container flex-center">
              <img
                src="../assets/AirQuality/key_benefits/key_benefits_1.svg"
                alt="indoor health image"
              />
            </div>
            <div class="key-benefits-description-container">
              <p>View of indoor health</p>
              <p>
                At a glance view of indoor air health, at a time when people may
                be more sensitive than ever about the air they breathe.
              </p>
            </div>
          </div>
          <div class="individual-benefit">
            <div class="benefit-image-container flex-center">
              <img
                src="../assets/AirQuality/key_benefits/key_benefits_2.svg"
                alt="numerous indicators image"
              />
            </div>
            <div class="key-benefits-description-container">
              <p>Numerous indicators</p>
              <p>
                Coverage across numerous indicators, from gases to airborne
                pollutants.
              </p>
            </div>
          </div>
          <div class="individual-benefit">
            <div class="benefit-image-container flex-center">
              <img
                src="../assets/AirQuality/key_benefits/key_benefits_3.svg"
                alt="automation image"
              />
            </div>
            <div class="key-benefits-description-container">
              <p>Automation</p>
              <p>
                Set-it-and-forget-it automation on updates minimizes management.
              </p>
            </div>
          </div>
          <div class="individual-benefit">
            <div class="benefit-image-container flex-center">
              <img
                src="../assets/AirQuality/key_benefits/key_benefits_4.svg"
                alt="flexible technical model image"
              />
            </div>
            <div class="key-benefits-description-container">
              <p>Flexible technical model</p>
              <p>
                Flexible technical model, using existing building systems
                sensors, or Telecine and its partners can supply.
              </p>
            </div>
          </div>
          <div class="individual-benefit">
            <div class="benefit-image-container flex-center">
              <img
                src="../assets/AirQuality/key_benefits/key_benefits_5.svg"
                alt="content management image"
              />
            </div>
            <div class="key-benefits-description-container">
              <p>Content management</p>
              <p>
                Content management software-friendly HTML5 content templates
                work across almost any digital signage network.
              </p>
            </div>
          </div>
          <div class="individual-benefit">
            <div class="benefit-image-container flex-center">
              <img
                src="../assets/AirQuality/key_benefits/key_benefits_6.svg"
                alt="accessible graphics image"
              />
            </div>
            <div class="key-benefits-description-container">
              <p>Accessible graphics</p>
              <p>
                Simplified, easily understood graphics showing readings are
                good, requiring caution or unhealthy.
              </p>
            </div>
          </div>
          <div class="individual-benefit">
            <div class="benefit-image-container flex-center">
              <img
                src="../assets/AirQuality/key_benefits/key_benefits_7.svg"
                alt="scalable image"
              />
            </div>
            <div class="key-benefits-description-container">
              <p>Scalable</p>
              <p>
                Easily scalable content delivery, regardless of the venue and
                network's size and complexity.
              </p>
            </div>
          </div>
        </div>
        <div
          class="arrow-container"
          @click.prevent="scrollPageTo('page4-inner-main-container')"
        >
          <div
            class="down-arrow white"
            role="button"
            tabindex="0"
            aria-label="move to next page arrow"
          ></div>
        </div>
      </div>
    </div>
    <div class="page3-lower-background-main-container"></div>

    <div id="inner-container-4" class="inner-container">
      <div class="page4-background"></div>
      <div class="page4-inner-main-container">
        <div class="metrics-container">
          <p class="page-heading-text">Air Quality Metrics</p>
          <div class="metrics">
            <p>- Indoor Temperature</p>
            <p>- Indoor Humidity</p>
            <p>- Indoor Air Pressure</p>
            <p>- Carbon Dioxide (CO2)</p>
            <p>- Carbon Monoxide (CO)</p>
            <p v-if="currentViewport != 'tablet'">
              - Total Volatile Organic Compounds (TVOC)
            </p>
            <p>- Particulates (PM2.5)</p>
            <p>- Ozone (O3)</p>
            <p>- Nitrous Oxide (NO2)</p>
          </div>
        </div>
        <div class="background-metrics">
          <!-- <img src="../assets/AirQuality/Air_Quality_Metrics.png" /> -->
        </div>
        <div></div>
      </div>
      <div
        class="arrow-container"
        @click.prevent="scrollPageTo('page5-inner-main-container')"
      >
        <div
          class="down-arrow white"
          role="button"
          tabindex="0"
          aria-label="move to next page arrow"
        ></div>
      </div>
    </div>
    <div class="page4-lower-background-main-container"></div>

    <div id="inner-container-5" class="inner-container">
      <div class="page5-inner-main-container">
        <p class="page-heading-text">Premium Content</p>
        <div class="tv-images-container">
          <div class="tv-images-individual-container">
            <img
              v-if="currentViewport != 'mobile'"
              src="../assets/AirQuality/AQ-Premium_Content_Left_TV.png"
              alt="left tv image"
              :style="tvTopBottom"
            />
            <img
              v-else
              src="../assets/AirQuality/Mobile_assets/Premium_Content-TV_1.png"
              alt="left tv image"
            />
          </div>
          <div class="tv-images-individual-container">
            <img
              v-if="currentViewport != 'mobile'"
              src="../assets/AirQuality/AQ-Premium_Content_Right_TV.png.png"
              alt="right tv image"
              :style="tvTopBottom"
            />
            <img
              v-else
              src="../assets/AirQuality/Mobile_assets/Premium_Content-TV_2.png"
              alt="right tv image"
            />
          </div>
          <!-- <p>* Live Data from the Telecine Office</p> -->
        </div>
      </div>
      <div
        class="arrow-container"
        @click.prevent="scrollPageTo('page6-inner-main-container')"
      >
        <div
          class="down-arrow white"
          role="button"
          tabindex="0"
          aria-label="move to next page arrow"
        ></div>
      </div>
    </div>

    <div id="inner-container-6" class="inner-container">
      <div class="page6-background"></div>
      <div class="page6-inner-main-container">
        <div class="video-container">
          <video
            class="video-image"
            poster="../assets/AirQuality/AQ_Logo.png"
            controls
            preload="auto"
          >
            <source
              src="../assets/AirQuality/video/Air_Quality_Website_Video_55mb.mp4"
            />
          </video>
          <!-- <img
            v-if="currentViewport === 'mobile'"
            class="play-button"
            src="../assets/AirQuality/Mobile_assets/Play_Button.svg"
            alt="play button"
          /> -->
          <div class="overlay" style="display: none"></div>
        </div>
      </div>
      <div
        class="arrow-container"
        @click.prevent="scrollPageTo('page8-inner-main-container')"
      >
        <div
          class="down-arrow white"
          role="button"
          tabindex="0"
          aria-label="move to next page arrow"
        ></div>
      </div>
    </div>

    <!-- <div id="inner-container-7" class="inner-container">
      <div class="page7-background"></div>
      <div class="page7-inner-main-container">
        <p class="page-heading-text">Pricing starting at</p>
        <div><span>$74.99</span><span>/Month</span></div>
        <div
          class="contact-us-button-container"
          @click.prevent="scrollPageTo('page8-inner-main-container')"
        >
          <p>Want to know more?</p>
          <p>Contact Us</p>
        </div>
      </div>
    </div>
    <div class="page7-lower-background-main-container">
      <div class="page7-lower-background-container"></div>
    </div> -->

    <div id="inner-container-8" class="inner-container">
      <div class="page8-inner-main-container">
        <p class="page-heading-text">Want to know more?</p>
        <div class="contact-info-main-container">
          <form
            class="form-container"
            @submit.prevent="onSubmitContactForm('contact-form')"
          >
            <div>
              <img
                src="../assets/AirQuality/AQ_Logo.png"
                alt="Air Quality Logo"
              />
            </div>
            <input
              placeholder="Name*"
              v-model="name"
              required
              @blur="validateName"
            />
            <!-- <p v-if="errorName" class="error">{{ errorName }}</p> -->
            <input
              type="email"
              placeholder="Email*"
              v-model="email"
              required
              @blur="validateEmail"
              @change="validateEmail"
            />
            <!-- <p v-if="errorEmail" class="error">{{ errorEmail }}</p> -->
            <textarea placeholder="Message" name="body" v-model="message" />
            <p class="required">*Required</p>
            <p v-if="messageSent" class="message-sent">
              Your message is sent successfully.
            </p>
            <button>Contact Us</button>
          </form>
          <footer class="info-container">
            <div class="powered-by">
              <p>Powered By</p>
              <a
                href="https://www.telecine.ca/"
                target="_blank"
                aria-label="telecine website link"
              >
                <img
                  src="../assets/AirQuality/Telecine_Logo.png"
                  alt="telecine logo"
                  role="button"
                  tabindex="0"
                />
              </a>
            </div>
            <p class="address">
              2281 rue Ste-Catherine Est Montreal, QC H2K 2J3
            </p>
            <p class="email">info@telecine.ca</p>
            <div class="number-container">
              <p>+ 1 514.879.1177</p>
              <p>+ 1 877.444.1177</p>
            </div>
          </footer>
        </div>
        <div class="bottom-image" v-if="currentViewport === 'tablet'"></div>
      </div>
      <!-- <div
        v-if="currentViewport != 'mobile'"
        class="arrow-container"
        @click.prevent="scrollPageTo('top-image-container')"
      >
        <div v-if="currentViewport === 'desktop'" class="up-arrow"></div>
      </div> -->
    </div>

    <!-- <div
      v-if="currentViewport === 'tablet'"
      id="inner-container-9"
      class="inner-container"
    >
      <div class="page9-inner-main-container"></div>
    </div> -->
  </main>
</template>

<script>
import sharedData from "../utilities/sharedData";
import Modal from "./Modal.vue";

export default {
  name: "AirQuality",
  components: { Modal },
  setup() {
    const {
      main,
      availHeight,
      availWidth,
      innerHeight,
      innerWidth,
      scrollPageTo,
      toggleMenu,
      isMenuOpen,
      isCovering,
      name,
      email,
      message,
      errorEmail,
      errorName,
      validateEmail,
      validateName,
      isContactButtonDisabled,
      onSubmitContactForm,
      messageSent,
      currentScroll,
      tvTopBottom,
      currentViewport,
      aboutUsParagraph1Text,
      isSideMenuVisible,
      isMoveToTopVisible,
      showModal,
    } = sharedData();

    return {
      main,
      availHeight,
      availWidth,
      innerHeight,
      innerWidth,
      scrollPageTo,
      toggleMenu,
      isMenuOpen,
      isCovering,
      name,
      email,
      message,
      errorEmail,
      errorName,
      validateEmail,
      validateName,
      isContactButtonDisabled,
      onSubmitContactForm,
      messageSent,
      currentScroll,
      tvTopBottom,
      currentViewport,
      aboutUsParagraph1Text,
      isSideMenuVisible,
      isMoveToTopVisible,
      showModal,
    };
  },
};
</script>

<style scoped>
@import url("../css/global.css");

.main-container {
  --dark-primary-color: #002c3b;
  --light-primary-color: #0096c7;
}

.menu p:nth-of-type(odd) {
  background: #0096c7;
}
.menu p:nth-of-type(even) {
  background: #008ebc;
}

.menu p:nth-of-type(odd):hover {
  background: #008ebd;
}
.menu p:nth-of-type(even):hover {
  background: #0086b2;
}

input:focus,
textarea:focus {
  outline: none !important;
  border-color: #0096c7;
}

.top-image-container {
  background-image: url("../assets/AirQuality/AQ-Top_Image.png");
}

.top-first-big-overlay {
  background-image: url("../assets/AirQuality/AQ-Image_2-Background_Overlay-Soft_Light.png");
}

.page2-background {
  background-image: url("../assets/AirQuality/Page2_Light_Background.png");
}

.page2-tv-image-inner-container {
  background-image: url("../assets/AirQuality/AQ-Top_TV.png");
}

.page3-background {
  background-image: url("../assets/AirQuality/key_benefits_svg_background.svg");
}

.page4-background {
  background-image: url("../assets/AirQuality/Air_Quality_Metrics_Background.png");
}

.metrics-container {
  background-image: url("../assets/AirQuality/Air_Quality_Metrics_Image_Overlay.svg");
}

.background-metrics {
  background-image: url("../assets/AirQuality/Air_Quality_Metrics.png");
}

.page6-background {
  background-image: url("../assets/AirQuality/Video_Background_New.png");
}

.page7-lower-background-container {
  background-image: url("../assets/AirQuality/test.png");
}

.page9-inner-main-container {
  background-image: url("../assets/AirQuality/Air_Quality_Metrics_Backdrop.svg");
}

@media only screen and (max-width: 576px) and (orientation: portrait) {
  .top-image-container {
    background-position: 90% 0%;
  }

  .page2-tv-image-inner-container {
    background-image: url("../assets/AirQuality/Mobile_assets/Who_We_Are-TV.png");
  }

  .page3-background {
    background-image: url("../assets/AirQuality/Mobile_assets/Key_Metrics_New_Resized.svg");
  }

  .page4-background {
    background-image: url("../assets/AirQuality/Mobile_assets/Air_Quality_Metrics_Backdrop.svg");
  }

  .page6-background {
    background-image: url("../assets/AirQuality/Mobile_assets/Video_Backdrop-Top.svg"),
      url("../assets/AirQuality/Mobile_assets/Video_Backdrop-Bottom.svg");
  }

  .page6-inner-main-container {
    background-image: url("../assets/AirQuality/Mobile_assets/Video_Background.png");
  }

  .menu-icon {
    background-image: url("../assets/AirQuality/Mobile_assets/Menu.svg");
  }
}

@media only screen and (min-width: 577px) and (max-width: 1024px) and (orientation: portrait) {
  .top-image-container {
    background-position: 90% 0%;
  }

  .page2-tv-image-inner-container {
    background-image: url("../assets/AirQuality/Mobile_assets/Who_We_Are-TV.png");
  }

  .page3-background {
    background-image: url("../assets/AirQuality/Tablet_assets/Key_Benefits_Background.svg");
  }

  .metrics-container {
    background-image: url("../assets/AirQuality/Tablet_assets/Key_Benefits_Backdrop-Transparent_Gradient.svg");
  }

  .page6-background {
    background-image: url("../assets/AirQuality/Tablet_assets/Video_Backdrop.svg");
  }

  .page7-lower-background-container {
    background-image: url("../assets/AirQuality/Tablet_assets/SVG_Before_Contact_Form_Page.svg");
  }

  .page9-inner-main-container {
    background-image: url("../assets/AirQuality/Tablet_assets/Air_Quality_Metrics_Backdrop.svg");
  }

  .bottom-image {
    background-image: url("../assets/AirQuality/Air_Quality_Metrics_Backdrop.svg");
  }
}
</style>
