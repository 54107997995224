<template>
  <main class="main-container mandatory-scroll-snapping" ref="main">
    <Teleport to="body">
      <!-- use the modal component, pass in the prop -->
      <modal
        :show="showModal"
        currentPage="finfacts"
        @close="showModal = false"
        @createNewContact="onSubmitContactForm('newsletter')"
        v-model="email"
      >
      </modal>
    </Teleport>
    <div v-if="isSideMenuVisible" class="fixed-menu-container">
      <transition name="menu" mode="out-in" appear>
        <div
          v-if="
            !isMenuOpen &&
            (currentViewport === 'tablet' || currentViewport === 'mobile') &&
            isMoveToTopVisible
          "
          class="fixed-move-to-top"
          @click.prevent="scrollPageTo('top')"
          tabindex="0"
          role="button"
          aria-label="move to top button"
        ></div>
        <div
          v-else-if="!isMenuOpen"
          class="menu-icon"
          @click="toggleMenu"
          tabindex="0"
          role="button"
        ></div>
        <div v-else class="menu">
          <p @click="toggleMenu">X</p>

          <p
            v-if="currentViewport != 'desktop'"
            @click.prevent="scrollPageTo('top')"
          >
            HOME
          </p>
          <p @click.prevent="scrollPageTo('page2-inner-main-container')">
            ABOUT
          </p>
          <p @click.prevent="scrollPageTo('page3-inner-main-container')">
            FEATURES
          </p>
          <p @click.prevent="scrollPageTo('page3-inner-main-container')">
            Key Benefits
          </p>
          <p @click.prevent="scrollPageTo('page4-inner-main-container')">
            Finfacts Categories
          </p>
          <p @click.prevent="scrollPageTo('page5-inner-main-container')">
            Content Samples
          </p>
          <!-- <p @click.prevent="scrollPageTo('page7-inner-main-container')">
            Pricing
          </p> -->
          <p @click.prevent="scrollPageTo('page8-inner-main-container')">
            Contact
          </p>
          <p v-if="currentViewport === 'desktop'"></p>
        </div>
      </transition>
    </div>

    <transition name="to-top" mode="out-in" appear>
      <div
        v-if="isSideMenuVisible && currentViewport === 'desktop'"
        class="fixed-move-to-top"
        @click.prevent="scrollPageTo('top')"
        tabindex="0"
        role="button"
        aria-label="move to top button"
      ></div>
    </transition>
    <div id="inner-container-1" class="inner-container top">
      <transition name="top-image" appear>
        <div class="top-image-container">
          <!-- <img src="../assets/AirQuality/AQ-Top_Image.png" /> -->
        </div>
      </transition>
      <div
        class="top-first-small-overlay"
        :class="{ 'cover-top-image': isCovering }"
      ></div>
      <div class="top-first-big-overlay">
        <header class="header-container">
          <div class="logo-container">
            <img src="../assets/Finfacts/FF_Logo.png" alt="logo" />
          </div>
          <div class="navbar-container flex-center">
            <nav>
              <ul>
                <li
                  class="flex-center"
                  @click.prevent="scrollPageTo('page2-inner-main-container')"
                >
                  ABOUT
                </li>
                <li
                  class="flex-center"
                  @click.prevent="scrollPageTo('page3-inner-main-container')"
                >
                  FEATURES
                </li>
                <li
                  class="flex-center"
                  @click.prevent="scrollPageTo('page8-inner-main-container')"
                >
                  CONTACT
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <transition name="page1-text" appear>
          <div class="page1-description">
            <h1>Finance made accessible.</h1>
            <p>
              With a curated list of FDIC financial facts, our digital signage
              solution will help the average person navigate finance.
            </p>
          </div>
        </transition>
      </div>
      <div class="arrow-container">
        <div
          class="down-arrow dark"
          @click.prevent="scrollPageTo('page2-inner-main-container')"
          role="button"
          tabindex="0"
          aria-label="move to next page arrow"
        ></div>
      </div>
      <a
        class="telecine-logo"
        href="https://www.telecine.ca/"
        target="_blank"
        aria-label="telecine website link"
      ></a>
    </div>

    <div id="inner-container-2" class="inner-container">
      <div class="page2-background"></div>
      <div class="page2-inner-main-container">
        <div class="page2-description flex-center">
          <p class="page-heading-text">Who we are</p>
          <p v-if="currentViewport != 'mobile'">
            Educate, advise, and influence your new and existing customers in
            and around your branches with a series of relevant but timeless
            animated content messages for digital signage and other customer
            communications platforms. FinFacts is a ready-to-go subscription
            content library built around everyday banking information and
            guidance, adapted from FDIC material that's already fully
            appropriate and approved for bank marketing and customer
            communications.
          </p>
          <p v-else>
            Educate, advise, and influence your new and existing customers in
            and around your branches with a series of relevant but timeless
            animated content messages for digital signage and other customer
            communications platforms. FinFacts is a ready-to-go subscription
            content library built around everyday banking information, adapted
            from FDIC material.
          </p>
          <!-- <p>
            {{ aboutUsParagraph1Text }}
          </p>
          <p v-if="currentViewport != 'mobile'">
            Dynamic, templated digital charts that both show and explain current
            conditions in simple, at-a-glance graphics, based on data from
            indoor air quality sensors.
          </p> -->
        </div>
        <div class="page2-tv-image-container">
          <div class="page2-tv-image-inner-container"></div>
        </div>
        <div
          class="arrow-container"
          @click.prevent="scrollPageTo('page3-inner-main-container')"
        >
          <div
            class="down-arrow dark"
            role="button"
            tabindex="0"
            aria-label="move to next page arrow"
          ></div>
        </div>
      </div>
    </div>

    <div id="inner-container-3" class="inner-container">
      <div class="page3-background"></div>
      <div class="page3-inner-main-container">
        <p class="page-heading-text">Key Benefits</p>
        <!-- <div
          class="key-benefits"
          :class="[currentScroll >= 22 ? 'key-benefits-appear' : '']"
        > -->
        <div class="key-benefits">
          <div class="individual-benefit">
            <div class="benefit-image-container flex-center">
              <img
                src="../assets/Finfacts/key_benefits/Quality.svg"
                alt="indoor health image"
              />
            </div>
            <div class="key-benefits-description-container">
              <p>High Quality</p>
              <p>
                High quality, attractive and useful content for in-branch
                customer messaging.
              </p>
            </div>
          </div>
          <div class="individual-benefit">
            <div class="benefit-image-container flex-center">
              <img
                src="../assets/Finfacts/key_benefits/Categories.svg"
                alt="numerous indicators image"
              />
            </div>
            <div class="key-benefits-description-container">
              <p>Multiple Categories</p>
              <p>
                Long shelf-life content, sorted by multiple subject categories.
              </p>
            </div>
          </div>
          <div class="individual-benefit">
            <div class="benefit-image-container flex-center">
              <img
                src="../assets/Finfacts/key_benefits/Inexpensive.svg"
                alt="automation image"
              />
            </div>
            <div class="key-benefits-description-container">
              <p>Inexpensive</p>
              <p>
                An inexpensive subscription versus the high costs of creating
                and managing original content.
              </p>
            </div>
          </div>
          <div class="individual-benefit">
            <div class="benefit-image-container flex-center">
              <img
                src="../assets/Finfacts/key_benefits/Customization.svg"
                alt="flexible technical model image"
              />
            </div>
            <div class="key-benefits-description-container">
              <p>Built in Customization</p>
              <p>
                Built-in customization options, with the library tailored to
                brand colors and logo.
              </p>
            </div>
          </div>
          <div class="individual-benefit">
            <div class="benefit-image-container flex-center">
              <img
                src="../assets/Finfacts/key_benefits/QR_Code.png"
                alt="content management image"
              />
            </div>
            <div class="key-benefits-description-container">
              <p>QR Code Interactivity</p>
              <p>
                Interactive extension, linking in-branch messaging by QR codes
                to deeper FDIC web material.
              </p>
            </div>
          </div>
          <div class="individual-benefit">
            <div class="benefit-image-container flex-center">
              <img
                src="../assets/Finfacts/key_benefits/Flexible.svg"
                alt="accessible graphics image"
              />
            </div>
            <div class="key-benefits-description-container">
              <p>Flexible Model</p>
              <p>
                Flexible subscription models - get specific content, like daily
                financial facts, or license the full library.
              </p>
            </div>
          </div>
          <div class="individual-benefit">
            <div class="benefit-image-container flex-center">
              <img
                src="../assets/Finfacts/key_benefits/Scalable.svg"
                alt="scalable image"
              />
            </div>
            <div class="key-benefits-description-container">
              <p>Easily Scalable</p>
              <p>
                Easily scalable content delivery, regardless of branch network
                size and complexity.
              </p>
            </div>
          </div>
        </div>
        <div
          class="arrow-container"
          @click.prevent="scrollPageTo('page4-inner-main-container')"
        >
          <div
            class="down-arrow white"
            role="button"
            tabindex="0"
            aria-label="move to next page arrow"
          ></div>
        </div>
      </div>
    </div>
    <div class="page3-lower-background-main-container"></div>

    <div id="inner-container-4" class="inner-container">
      <div class="page4-background"></div>
      <div class="page4-inner-main-container">
        <div class="metrics-container">
          <p class="page-heading-text">Main Categories</p>
          <div class="metrics">
            <p>- Youth Savings</p>
            <p>- Home Ownership</p>
            <p>- Fraud Detection</p>
            <p>MORE TO COME</p>
          </div>
        </div>
        <div class="background-metrics">
          <!-- <img src="../assets/AirQuality/Air_Quality_Metrics.png" /> -->
        </div>
        <div></div>
      </div>
      <div
        class="arrow-container"
        @click.prevent="scrollPageTo('page5-inner-main-container')"
      >
        <div
          class="down-arrow white"
          role="button"
          tabindex="0"
          aria-label="move to next page arrow"
        ></div>
      </div>
    </div>
    <div class="page4-lower-background-main-container"></div>

    <div id="inner-container-5" class="inner-container">
      <div class="page5-inner-main-container">
        <p class="page-heading-text">Premium Content</p>
        <div class="tv-images-container">
          <div class="tv-images-individual-container">
            <img
              v-if="currentViewport != 'mobile'"
              src="../assets/Finfacts/FF-Premium_Content_Left_TV.png"
              alt="left tv image"
              :style="tvTopBottom"
            />
            <img
              v-else
              src="../assets/Finfacts/Mobile_assets/FF_Premium_Content-TV_1.png"
              alt="left tv image"
            />
          </div>
          <div class="tv-images-individual-container">
            <img
              v-if="currentViewport != 'mobile'"
              src="../assets/Finfacts/FF-Premium_Content_Right_TV.png.png"
              alt="right tv image"
              :style="tvTopBottom"
            />
            <img
              v-else
              src="../assets/Finfacts/Mobile_assets/FF_Premium_Content-TV_2.png"
              alt="right tv image"
            />
          </div>
          <!-- <p>* Live Data from the Telecine Office</p> -->
        </div>
      </div>
      <div
        class="arrow-container"
        @click.prevent="scrollPageTo('page6-inner-main-container')"
      >
        <div
          class="down-arrow white"
          role="button"
          tabindex="0"
          aria-label="move to next page arrow"
        ></div>
      </div>
    </div>

    <div id="inner-container-6" class="inner-container">
      <div class="page6-background"></div>
      <div class="page6-inner-main-container">
        <div class="video-container">
          <img
            class="video-image"
            src="../assets/Finfacts/Finfacts_Video_Placeholder.png"
            alt="video"
          />
          <img
            v-if="currentViewport === 'mobile'"
            class="play-button"
            src="../assets/AirQuality/Mobile_assets/Play_Button.svg"
            alt="play button"
          />
          <div class="overlay"></div>
        </div>
      </div>
      <div
        class="arrow-container"
        @click.prevent="scrollPageTo('page8-inner-main-container')"
      >
        <div
          class="down-arrow white"
          role="button"
          tabindex="0"
          aria-label="move to next page arrow"
        ></div>
      </div>
    </div>

    <!-- <div id="inner-container-7" class="inner-container">
      <div class="page7-background"></div>
      <div class="page7-inner-main-container">
        <p class="page-heading-text">Pricing starting at</p>
        <div><span>$15.00</span><span>/Month</span></div>
        <div
          class="contact-us-button-container"
          @click.prevent="scrollPageTo('page8-inner-main-container')"
        >
          <p>Want to know more?</p>
          <p>Contact Us</p>
        </div>
      </div>
    </div>
    <div class="page7-lower-background-main-container">
      <div class="page7-lower-background-container"></div>
    </div> -->

    <div id="inner-container-8" class="inner-container">
      <div class="page8-inner-main-container">
        <p class="page-heading-text">Want to know more?</p>
        <div class="contact-info-main-container">
          <form
            class="form-container"
            @submit.prevent="onSubmitContactForm('contact-form')"
          >
            <div>
              <img src="../assets/Finfacts/FF_Logo.png" alt="Finfacts Logo" />
            </div>
            <input
              placeholder="Name*"
              v-model="name"
              required
              @blur="validateName"
            />
            <!-- <p v-if="errorName" class="error">{{ errorName }}</p> -->
            <input
              type="email"
              placeholder="Email*"
              v-model="email"
              required
              @blur="validateEmail"
              @change="validateEmail"
            />
            <!-- <p v-if="errorEmail" class="error">{{ errorEmail }}</p> -->
            <textarea placeholder="Message" name="body" v-model="message" />
            <p class="required">*Required</p>
            <p v-if="messageSent" class="message-sent">
              Your message is sent successfully.
            </p>
            <button>Contact Us</button>
          </form>
          <footer class="info-container">
            <div class="powered-by">
              <p>Powered By</p>
              <a
                href="https://www.telecine.ca/"
                target="_blank"
                aria-label="telecine website link"
              >
                <img
                  src="../assets/AirQuality/Telecine_Logo.png"
                  alt="telecine logo"
                  role="button"
                  tabindex="0"
                />
              </a>
            </div>
            <p class="address">
              2281 rue Ste-Catherine Est Montreal, QC H2K 2J3
            </p>
            <p class="email">info@telecine.ca</p>
            <div class="number-container">
              <p>+ 1 514.879.1177</p>
              <p>+ 1 877.444.1177</p>
            </div>
          </footer>
        </div>
        <div class="bottom-image" v-if="currentViewport === 'tablet'"></div>
      </div>
      <!-- <div
        v-if="currentViewport != 'mobile'"
        class="arrow-container"
        @click.prevent="scrollPageTo('top-image-container')"
      >
        <div v-if="currentViewport === 'desktop'" class="up-arrow"></div>
      </div> -->
    </div>

    <!-- <div
      v-if="currentViewport === 'tablet'"
      id="inner-container-9"
      class="inner-container"
    >
      <div class="page9-inner-main-container"></div>
    </div> -->
  </main>
</template>

<script>
import sharedData from "../utilities/sharedData";
import Modal from "./Modal.vue";

export default {
  name: "AirQuality",
  components: { Modal },
  setup() {
    const {
      main,
      availHeight,
      availWidth,
      innerHeight,
      innerWidth,
      scrollPageTo,
      toggleMenu,
      isMenuOpen,
      isCovering,
      name,
      email,
      message,
      errorEmail,
      errorName,
      validateEmail,
      validateName,
      isContactButtonDisabled,
      onSubmitContactForm,
      messageSent,
      currentScroll,
      tvTopBottom,
      currentViewport,
      aboutUsParagraph1Text,
      isSideMenuVisible,
      isMoveToTopVisible,
      showModal,
    } = sharedData();

    return {
      main,
      availHeight,
      availWidth,
      innerHeight,
      innerWidth,
      scrollPageTo,
      toggleMenu,
      isMenuOpen,
      isCovering,
      name,
      email,
      message,
      errorEmail,
      errorName,
      validateEmail,
      validateName,
      isContactButtonDisabled,
      onSubmitContactForm,
      messageSent,
      currentScroll,
      tvTopBottom,
      currentViewport,
      aboutUsParagraph1Text,
      isSideMenuVisible,
      isMoveToTopVisible,
      showModal,
    };
  },
};
</script>

<style scoped>
@import url("../css/global.css");
.main-container {
  --dark-primary-color: #2f3931;
  --light-primary-color: #6fc814;
  --erie-black: #1d1d1d;
}

.menu p:nth-of-type(odd) {
  background: #61a710;
}
.menu p:nth-of-type(even) {
  background: #5a9c10;
}

.menu p:nth-of-type(odd):hover {
  background: #5a9c10;
}
.menu p:nth-of-type(even):hover {
  background: #54910f;
}

.menu-icon {
  background-image: url("../assets/Finfacts/Menu.svg");
}

.fixed-move-to-top {
  background-image: url("../assets/Finfacts/Back_to_Top.svg");
}

input:focus,
textarea:focus {
  outline: none !important;
  border-color: #6fc814;
}

.individual-benefit {
  background: #e4f4d4;
}

.key-benefits-appear div:nth-child(5) img {
  width: 33%;
}

.background-metrics {
  height: 62%;
}

.contact-us-button-container,
.form-container button {
  color: var(--erie-black);
}

.top-image-container {
  background-image: url("../assets/Finfacts/FF-Top_Image.png");
}

.top-first-small-overlay {
  background-image: url("../assets/Finfacts/FF-Image_2-Background.png");
  mix-blend-mode: lighten;
  opacity: 0.7;
}

.top-first-big-overlay {
  background-image: url("../assets/Finfacts/FF-Image_2-Background_Overlay-Soft_Light.png");
}

.page2-background {
  background-image: url("../assets/Finfacts/FF_Page2_Light_Background.png");
}

.page2-tv-image-inner-container {
  background-image: url("../assets/Finfacts/FF-Top_TV.png");
}

.page3-background {
  background-image: url("../assets/Finfacts/key_benefits_svg_background.svg");
}

.page4-background {
  background-image: url("../assets/Finfacts/Finfacts_Metrics_Background.png");
  top: -9%;
  bottom: -7.3%;
}

.metrics-container {
  background-image: url("../assets/Finfacts/Finfacts_Metrics_Image_Overlay.svg");
}

.background-metrics {
  background-image: url("../assets/Finfacts/Finfacts_Metrics.png");
}

.page6-background {
  background-image: url("../assets/Finfacts/Video_Background_New.png");
}

.page7-lower-background-container {
  background-image: url("../assets/Finfacts/test.png");
}

.page9-inner-main-container {
  /* background-image: url("../assets/Finfacts/Air_Quality_Metrics_Backdrop.svg"); */
}

@media only screen and (max-width: 576px) and (orientation: portrait) {
  .page2-description p:not(:first-child) {
    font-size: 4.5vw;
  }

  .page4-background {
    top: 11%;
    bottom: -13%;
  }

  .metrics-container {
    height: 60%;
  }

  .metrics-container > p {
    height: 22.5%;
  }

  .metrics {
    height: 62%;
  }

  .metrics > p:not(:last-child) {
    font-family: EuropeBold;
  }

  .metrics > p:last-child {
    font-family: "Europe";
    font-size: 2.5vw;
    padding-left: 5.5%;
  }

  .background-metrics {
    height: 34%;
  }

  .page7-lower-background-container {
    top: -153%;
    bottom: -400%;
  }

  .page2-tv-image-inner-container {
    background-image: url("../assets/Finfacts/Mobile_assets/FF_Who_We_Are-TV.png");
  }

  .page3-background {
    background-image: url("../assets/Finfacts/Mobile_assets/Key_Metrics_New_Resized.svg");
  }

  .page4-background {
    /* background-image: url("../assets/Finfacts/Mobile_assets/Air_Quality_Metrics_Backdrop.svg"); */
  }

  .page6-background {
    background-image: url("../assets/Finfacts/Mobile_assets/Video_Backdrop-Top.svg"),
      url("../assets/Finfacts/Mobile_assets/Video_Backdrop-Bottom.svg");
  }

  .page6-inner-main-container {
    background-image: url("../assets/Finfacts/Mobile_assets/FF_Video_Background.png");
  }
  .menu-icon {
    background-image: url("../assets/Finfacts/Mobile_assets/Menu.svg");
  }

  @media (min-aspect-ratio: 50/93) {
    .page2-description p:not(:first-child) {
      font-size: 4vw;
    }

    .metrics-container > p {
      height: 27%;
    }
  }
}

@media only screen and (min-width: 577px) and (max-width: 1024px) and (orientation: portrait) {
  .top-image-container {
    /* background-position: 62% 0%; */
  }

  .page4-background {
    top: 12%;
    bottom: -14.3%;
  }

  .metrics-container {
    padding: 0 7%;
  }

  .metrics {
    background-position: bottom;
    text-align: center;
  }

  .metrics p {
    width: 33%;
  }

  .metrics p:not(:last-child) {
    font-family: EuropeBold;
  }

  .metrics p:last-child {
    width: 100%;
    font-size: 1.7vw;
    align-self: flex-start;
  }

  .background-metrics {
    height: 30%;
  }

  .page2-tv-image-inner-container {
    background-image: url("../assets/Finfacts/Mobile_assets/FF_Who_We_Are-TV.png");
  }

  .page3-background {
    background-image: url("../assets/Finfacts/Tablet_assets/Key_Benefits_Background.svg");
  }

  .metrics-container {
    background-image: url("../assets/Finfacts/Tablet_assets/Key_Benefits_Backdrop-Transparent_Gradient.svg");
  }

  .page6-background {
    background-image: url("../assets/Finfacts/Tablet_assets/Video_Backdrop.svg");
  }

  .page7-lower-background-container {
    background-image: url("../assets/Finfacts/Tablet_assets/SVG_Before_Contact_Form_Page.svg");
  }

  .page9-inner-main-container {
    background-image: url("../assets/Finfacts/Tablet_assets/Finfacts_Metrics_Backdrop.svg");
  }

  .bottom-image {
    background-image: url("../assets/Finfacts/Finfacts_Metrics_Backdrop.svg");
  }
}

@media screen and (min-width: 1025px) {
  .metrics p {
    font-family: EuropeBold;
    font-size: 2vw;
  }

  .metrics p:nth-of-type(4) {
    font-size: 1vw;
    font-family: Europe;
    padding-left: 7em;
  }

  /* .page3-background {
    background-image: url("../assets/Finfacts/key_benefits_svg_background.svg");
  } */

  /* .page4-background {
    background-image: url("../assets/Finfacts/Finfacts_Metrics_Backdrop.svg");
    top: -9%;
    bottom: -7.3%;
  } */

  /* .metrics-container {
    background-image: url("../assets/Finfacts/Finfacts_Metrics_Image_Overlay.svg");
  } */

  /* .page6-background {
    background-image: url("../assets/Finfacts/Video_Background_New.png");
  } */

  .page7-lower-background-container {
  }
}
</style>